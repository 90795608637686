/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import loadable from "@loadable/component";

// Constants
import { DEVICE_TYPE, NUMBER } from "../../../constants/app-constants";

// Components
import PilotFaqMobile from "../../ae.mobile/pilot-faq";
import PilotFaqDesktop from "../../ae.desktop/pilot-faq";

const CarListingFaq = ({
    cityName = "",
    platform,
    faqs,
    routeMatch
}) => {
    const isMobile = platform === DEVICE_TYPE.MOBILE;
    const [, forceRender] = useState();
    const { url = ""} = routeMatch;

    let urlSplit = url.split("/") || [];
    urlSplit = urlSplit.filter(split => split !== "");

    const locationName = urlSplit.length > 0 && urlSplit[urlSplit.length - 1]?.includes("uae") ? "UAE" :  cityName.toUpperCase();

    useEffect(() => {
        forceRender();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityName]);

    const Component = isMobile ? PilotFaqMobile : PilotFaqDesktop;

    const mobileProps = {
        heading: isMobile ? `FAQS FOR USED CARS IN ${locationName}`
            : `FREQUENTLY ASKED QUESTIONS ABOUT USED CARS`,
        isListingPage: true,
        chunkSize: isMobile ? NUMBER.ONE : NUMBER.FIVE,
        columns: isMobile || (!isMobile && faqs?.length <= NUMBER.FIVE) ? NUMBER.ONE : NUMBER.TWO
    };

    return (
        <React.Fragment>
            <Component {...mobileProps} listingFaq={faqs?.length ? faqs : []}/>
        </React.Fragment>

    );
};

CarListingFaq.propTypes = {
    cityName: PropTypes.string,
    platform: PropTypes.string,
    faqs: PropTypes.array,
    routeMatch: PropTypes.object
};

export default CarListingFaq;
