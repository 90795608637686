/* eslint-disable max-params */
export default (api) => {

    const fetchMakes = () => {
        return api.get(`/api/v1/masterdata/make`);
    };

    const fetchModels = (make) => {
        return api.get(`/api/v1/masterdata/models/${make}`, { parse: true });
    };

    const fetchVariants = (make, model, year) => {
        return api.get(`/api/v1/masterdata/variant-details/${make}/${model}/${year}`);
    };

    const fetchYears = (make, model) => {
        return api.get(`/api/v1/masterdata/year/${make}/${model}`);
    };

    const fetchCarSelfInspectionQuestions = (variant) => {
        return api.get(`/api/v1/masterdata/self-inspection-questions/${variant}`);
    };

    const fetchSellerTestimonials = (city) => {
        return api.get(`/api/v1/masterdata/testimonials${city ? `?city=${city}` : ""}`);
    };

    const fetchSellerABConfig = (gaid, experimentName = "seller_inspection_price") => {
        return api.get(`/api/v1/statsig/experiment?userId=${gaid}&experimentName=${experimentName}`);
    };

    return {
        fetchModels,
        fetchVariants,
        fetchYears,
        fetchMakes,
        fetchCarSelfInspectionQuestions,
        fetchSellerTestimonials,
        fetchSellerABConfig
    };
};
