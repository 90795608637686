/* eslint-disable max-statements */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

//Images
import IconRotate from "../../shared/images/icons/rotate-icon.svg";

// Components
import CarList from "../car-list";
import BuyCarBreadCrumb from "../buy-car-breadcrumb";
import MetaTagsCarListingV2 from "../../shared/meta-tags-car-listing-v2";
import SalesOfferListing from "../sales-offer-listing/component";
import CarListingFaq from "../../shared/car-listing-faq";
import SortBy from "../sort-by";
import SaleStripListing from "../sale-strip-listing";
import PageNotFoundAe from "../../../components/shared/page-not-found-ae";

// Constants
import { CURRENCY, DEFAULT_CITY, HTTP_CODES, NUMBER } from "../../../constants/app-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import carListingLocales from "../../../locales/desktop/car-listing";

// Utilities
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { appUrl } from "../../../constants/url-constants";
import ArrowIcon from "../../shared/arrow";
import FilterRevamp from "../filter-revamp";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import AppliedFilterTag from "../../shared/applied-filter-tag";
import Filters from "../../../utils/filters-v2";
import getUpdatedAppliedFilters from "../../../utils/filters-v2/get-updated-applied-filters";
import useCollapse from "../../../hooks/use-collapse";
import useOutsideClick from "../../../hooks/use-outside-click";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import SeoLinksListingPageV2 from "../../shared/seo-links-listing-page-v2/index";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

// eslint-disable-next-line complexity
const CarListing = ({
    fetchFiltersConnect,
    updateSortModalVisibilityConnect,
    metaContent,
    selectedCity,
    updateFilterOptionDataConnect,
    updateAppliedFiltersConnect,
    reloadCarListConnect,
    updateSelectedSuggestionsConnect,
    allFilters,
    urlOptions,
    clearAllFiltersConnect,
    appliedFilters,
    ramadan,
    isLoading,
    isFetched,
    isSortModalOpen,
    route,
    error,
    content,
    imageHost,
    totalCars,
    isSSR,
    selectedFilters,
    selectedSort,
    recentlyViewedCars,
    updateSSRStatusConnect,
    activeSaleConfig,
    isLoggedIn, updatedToken
}) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    const queryParams = parseLocationSearchParams(location.search);
    const isSeoPath = !Object.keys(queryParams)?.length;

    const [showAllText, setShowAllText] = useState(false);
    const { additionalData = {} } = metaContent || {};
    const [recentlyViewedTotal, setRecentlyViewedTotal] = useState(recentlyViewedCars?.length || NUMBER.ZERO);
    const isRecentlyViewed = queryParams?.isRecentlyViewed;
    const wishlistSimilarAppID = queryParams?.wsAppId || "";
    const isCustomRecommended = queryParams?.isCustomRecommended;

    const { active: isSaleActive = false, loginMandatory: loginMandatoryForSale } = activeSaleConfig || {};
    useEffect(() => {
        if (isSSR) {
            updateSSRStatusConnect(false);
            return;
        }
        try {
            const filterParams = { variant: "filterV5" };
            fetchFiltersConnect(filterParams);
        } catch (err) {
            // Handle if any error
        }
    }, [fetchFiltersConnect, isSSR, updateSSRStatusConnect]);

    const handleScroll = () => {
        if (isSortModalOpen) {
            updateSortModalVisibilityConnect(false);
        }
    };

    const { getHiddenItemsCount, getVisibleItems, containerRef } = useCollapse(appliedFilters, "filterTablet");

    const searchQuery = queryParams.search;

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSortModalOpen]);

    const onBreadcrumbClick = async ({ item, url }) => {
        await yieldToMainThread();
        if (item === "home") {
            history.push("/");
        } else {
            history.push(url);
        }
    };

    useEffect(() => {
        trackDesktopCustomEventsAE("b2cListingPageVisited");
    }, []);

    const showSalesBanner = !(appliedFilters || []).length && ramadan?.active && !isLoading && isFetched;

    const onToggleSort = async () => {
        // updateSelectedOnScreenFilterConnect("");
        // updateAllFiltersVisibilityConnect(false);
        await yieldToMainThread();
        updateSortModalVisibilityConnect(!isSortModalOpen);
    };
    const toggleText = () => {
        setShowAllText(!showAllText);
    };

    const onRemoveFilter = async (data) => {
        await yieldToMainThread();
        updateFilterOptionDataConnect(data);
        updateAppliedFiltersConnect();
        await yieldToMainThread();
        const updatedFilters = getUpdatedAppliedFilters(data, appliedFilters);
        const filters = new Filters({ cityCode: selectedCity.code, cityName: selectedCity.name });
        const { relativeURL } = filters.getListingPayload({
            selectedFilters: updatedFilters, filters: allFilters, options: {
                ...urlOptions,
                ...(
                    searchQuery && {
                        search: searchQuery
                    }
                )
            }
        });
        updateSelectedSuggestionsConnect(data);
        await yieldToMainThread();
        history.replace(relativeURL);
        reloadCarListConnect(true);
        trackDesktopCustomEventsAE("clearFiltertag", { eventLabel: data?.filterKey });
    };

    const clearAllFilters = async () => {
        await yieldToMainThread();
        clearAllFiltersConnect();
        const filters = new Filters({ cityCode: selectedCity.code, cityName: selectedCity.name });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: appliedFilters, filters: {}, resetFilter: true });
        await yieldToMainThread();
        history.replace(relativeURL);
        reloadCarListConnect(true);
        trackDesktopCustomEventsAE("clearAllFilters", { eventLabel: "listing" });
    };

    const popupRef = useOutsideClick(() => {
        if (isSortModalOpen) updateSortModalVisibilityConnect(false);
    });
    const getCtaText = () => {
        if (isRecentlyViewed) {
            return "Recently Viewed";
        } else if (isCustomRecommended) {
            return "Recommended";
        } else {
            return selectedSort?.groupName || selectedSort?.displayText || "Sort";
        }
    };

    const getTotalVehicleCount = () => {
        if (isRecentlyViewed) {
            return recentlyViewedTotal;
        } else if (wishlistSimilarAppID) {
            return similarCarsCount;
        } else {
            return totalCars;
        }
    };

    if (error?.message === "Invalid s-path" || error?.error === HTTP_CODES.NOT_FOUND) {
        return <PageNotFoundAe />;
    }

    const getPriceLabel = (price) => {
        if (price < NUMBER.THOUSAND) {
            return Math.round(price);
        }
        const nearestRoundOff = Math.round(price / NUMBER.THOUSAND);
        return `${nearestRoundOff}k`;
    };

    return (
        <React.Fragment>
            <MetaTagsCarListingV2
                cityName={selectedCity.name || DEFAULT_CITY.AE.city_name}
                metaContent={metaContent}
                location={location}
                match={match}
                content={content}
                imageHost={imageHost}
                appliedFilters={appliedFilters}
            />
            <div styleName={"styles.listingPageWrapper"}>
                <div styleName={"styles.container"}>
                    <div styleName={"styles.flexRow"}>
                        <div styleName={`styles.leftGrid`}>
                            <FilterRevamp listingCount={totalCars} />
                        </div>
                        <div styleName={"styles.rightGrid"}>
                            <div styleName={"styles.filterWrap"}>
                                <div>
                                    <BuyCarBreadCrumb onClickCallback={onBreadcrumbClick} />
                                </div>
                                {showSalesBanner && <div styleName={"styles.saleBannerWrap"}>
                                    <SalesOfferListing />
                                </div>}
                            </div>
                            <div styleName="styles.seoContentWrap">
                                <div styleName={"styles.sortByWrapper"}>
                                    <h1 styleName={"styles.heading"}>{getTotalVehicleCount() > NUMBER.ZERO && <span>{getTotalVehicleCount()}</span>} {metaContent.h1Tag}</h1>
                                    <div styleName={"styles.recentlyAdd"}>
                                        <div styleName={"styles.recentlyDropdown"} ref={popupRef}>
                                            <a onClick={onToggleSort} className="filter-section" styleName={"styles.recentlyItem"}>Sort By:</a><span onClick={onToggleSort} styleName={"styles.selectedText"}>{getCtaText()}<ArrowIcon darkblue /></span>
                                            {isSortModalOpen && <SortBy onToggleSort={onToggleSort} />}
                                        </div>
                                    </div>
                                </div>
                                {(additionalData?.description || additionalData?.structuredDescription) && (
                                    <div styleName="styles.seoWrapper">
                                        <div styleName={"styles.seoDescriptionWrap"}>
                                            <div styleName={!showAllText ? "styles.truncatedWrap" : "styles.paraMargin"} dangerouslySetInnerHTML={{ __html: additionalData?.structuredDescription || additionalData?.description }} />
                                            <div style={{ visibility: showAllText ? "visible" : "hidden", height: showAllText ? "auto" : NUMBER.ZERO }}>
                                                {additionalData?.makeBasedDescription?.EN &&
                                                    <p styleName={"styles.paraMargin"} dangerouslySetInnerHTML={{ __html: additionalData?.makeBasedDescription?.EN }} />
                                                }
                                                {additionalData?.makeBasedDescription?.AR &&
                                                    <p styleName={"styles.arabicContent"}>{additionalData?.makeBasedDescription?.AR}</p>
                                                }
                                            </div>
                                            {!!additionalData?.topCars && (
                                                <React.Fragment>
                                                    <div style={{ visibility: showAllText ? "visible" : "hidden", height: showAllText ? "auto" : NUMBER.ZERO }}>
                                                        <div styleName={"styles.recentlyAddedHeader"}>
                                                            <span>{additionalData?.topCars?.heading}</span>
                                                        </div>
                                                        <div styleName={"styles.recentlyAddedWrap styles.topCars"}>
                                                            {additionalData?.topCars?.data.map(
                                                                (item) => (
                                                                    <div styleName={"styles.dFlex"} key={`${item?.make}_${item?.model}`}>
                                                                        <a
                                                                            href={`${appUrl()}/${item?.url}`}
                                                                            title={`Used ${item?.make} ${item?.model || ""} cars in Dubai`}
                                                                        >Used {item?.make}{item?.model ? ` ${item.model}` : ""} cars in Dubai</a>
                                                                        <p>
                                                                            {item.numberOfCars && <span>{item.numberOfCars} cars</span>}
                                                                            {(item?.minPrice !== null && item?.maxPrice !== null) && <span>{!!item.numberOfCars && ", "}AED {getPriceLabel(item.minPrice)} - {getPriceLabel(item.maxPrice)}</span>}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        {(additionalData?.description?.length || additionalData?.structuredDescription?.length) > NUMBER.NINETY && (
                                            <span styleName={`styles.readMore ${showAllText ? "styles.readLess" : ""}`} onClick={toggleText}>
                                                {showAllText ? "Read less" : "Read more"}
                                            </span>
                                        )}
                                    </div>
                                )}
                                <div styleName={`styles.appliedFilterSection ${selectedFilters.length > 0 ? "" : "styles.zeroMargin"}`}>
                                    {selectedFilters.length > 0 && <div styleName={"styles.clearText"} onClick={clearAllFilters}>CLEAR ALL <img src={IconRotate} alt="" /></div>}
                                    <div styleName={"styles.appliedFilterWrap"} ref={containerRef}>
                                        {
                                            getVisibleItems().reverse().map((appliedFilter, index) => {
                                                const makeFilter = {
                                                    filterKey: appliedFilter.filterKey,
                                                    optionKey: appliedFilter.optionKey,
                                                    displayText: appliedFilter.optionKey
                                                };
                                                return (appliedFilter.isSubFacetFullySelected) ?
                                                    appliedFilter.key === "make" && appliedFilter.filterKey === "make" &&

                                                    <AppliedFilterTag key={`appliedFilters_${index}`}
                                                        onRemove={onRemoveFilter} filter={makeFilter} allFilters={allFilters} currency={CURRENCY.AE} /> :

                                                    <AppliedFilterTag key={`appliedFilters_${index}`}
                                                        onRemove={onRemoveFilter} filter={appliedFilter} allFilters={allFilters} currency={CURRENCY.AE} />;
                                            })
                                        }
                                        {getHiddenItemsCount() > 0 && (
                                            <div>
                                                <AppliedFilterTag text={`${getHiddenItemsCount()} more`} showClose={false} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isSaleActive && !isLoggedIn && loginMandatoryForSale && !updatedToken && <SaleStripListing />}
                            <CarList
                                route={route}
                                locales={carListingLocales.carList}
                                selectedCityCode={selectedCity.code}
                                selectedCityName={selectedCity.name}
                                selectedCity={selectedCity}
                                setRecentlyViewedTotal={setRecentlyViewedTotal}
                            />
                        </div>
                    </div>
                    <section styleName={"styles.padding styles.listingFaqWrapper"}>
                        <div>
                            <CarListingFaq cityName={selectedCity.name || DEFAULT_CITY.AE.city_name} routeMatch={match} />
                        </div>
                    </section>
                    {isSeoPath && <section styleName={"styles.seoLinkWrap"}>
                        <SeoLinksListingPageV2 pageSource={ROUTE_NAME.LISTING} />
                    </section>}
                </div>
            </div>
        </React.Fragment>
    );
};

CarListing.propTypes = {
    fetchFiltersConnect: PropTypes.func,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    updateAllFiltersVisibilityConnect: PropTypes.func,
    updateSortModalVisibilityConnect: PropTypes.func,
    metaContent: PropTypes.object,
    updateFilterOptionDataConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    updateSelectedSuggestionsConnect: PropTypes.func,
    allFilters: PropTypes.object,
    urlOptions: PropTypes.object,
    clearAllFiltersConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    ramadan: PropTypes.object,
    error: PropTypes.string,
    appliedFilters: PropTypes.array,
    isLoading: PropTypes.bool,
    isFetched: PropTypes.bool,
    isSortModalOpen: PropTypes.bool,
    route: PropTypes.object,
    content: PropTypes.array,
    imageHost: PropTypes.string,
    totalCars: PropTypes.number,
    isSSR: PropTypes.bool,
    selectedFilters: PropTypes.array,
    selectedSort: PropTypes.object,
    recentlyViewedCars: PropTypes.array,
    updateSSRStatusConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    updatedToken: PropTypes.string,
    activeSaleConfig: PropTypes.object
};

export default CarListing;
